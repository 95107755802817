<template>
  <div class="maxbox">
    <div>
      <!-- 头部标题 -->
      <div class="top">
        <p>我的资产</p>
      </div>
      <!-- 主要内容区域 -->
      <div class="main">
        <!-- 返回按钮 -->
        <button @click="goback" class="return"><img src="@/assets/images/left-arrow.png" alt="返回"></button>
        <!-- 奖金内容容器 -->
        <div class="main-content">
          <!-- 奖金标题与金额显示 -->
          <p id="totalRewards">SBS总额：{{ totalRewards }} SBS</p>
          <p id="dailyDividend">我的矿机每日分红：{{ dailyDividend }} SBS</p>
          <p id="firstLevelBonus">我的一代分红：{{ firstLevelBonus }} SBS</p>
          <p id="secondLevelBonus">我的二代分红：{{ secondLevelBonus }} SBS</p>
          <p id="thirdToTenthLevelBonus">我的3-15代分红：{{ thirdToTenthLevelBonus }} SBS</p>
          <p id="withdrawableDividends">可提余额：{{ withdrawableDividends }} SBS</p>
          <!-- 提现输入与按钮 -->
          <input type="number" v-model="amountToWithdraw" placeholder="输入提现金额">
          <button @click="withdrawDividends" class="withdraw-button">提现</button><br>
          <!-- 分红明细标题（固定位置） -->
          <h3>结算分红明细</h3>
          <!-- 分红明细列表，增加滚动功能 -->
          <div class="dividends-scrollable">
            <ul>
              <li v-for="(dividend, index) in reverseDividendsPerRound" :key="index">
                结算日期: {{ formatTimestamp(index) }} 金额: {{ formatUSDT(dividend) }} SBS
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'income',
  data() {
    return {
      totalRewards: '加载中...',
      dailyDividend: '加载中...',
      firstLevelBonus: '加载中...',
      secondLevelBonus: '加载中...',
      thirdToTenthLevelBonus: '加载中...',
      withdrawableDividends: '加载中...',
      amountToWithdraw: 0,
      dividendsPerRound: [], // 存储每次结算的分红明细
    }
  },
  computed: {
    // 倒序排列的分红明细
    reverseDividendsPerRound() {
      return [...this.dividendsPerRound].reverse();
    }
  },
  methods: {
    // 返回上一页
    goback() {
      this.$router.go(-1);
    },
    // 连接钱包
    async connectWallet() {
      if (!window.ethereum) {
        console.error("未检测到Ethereum提供商。请安装MetaMask。");
        return null;
      }
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        return new ethers.providers.Web3Provider(window.ethereum);
      } catch (error) {
        console.error("用户拒绝了账户访问请求", error);
        return null;
      }
    },
    // 获取用户奖金信息
    async getUserBonuses() {
  const provider = await this.connectWallet();
  if (!provider) return;

  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractABI, signer);

  try {
    const [
      totalDividends,
      dailyDividend,
      firstLevelBonus,
      secondLevelBonus,
      thirdToTenthLevelBonus,
      withdrawableDividends,
    ] = await Promise.all([
      contract.getTotalDividends(),
      contract.getDailyDividend(),
      contract.getFirstLevelBonus(),
      contract.getSecondLevelBonus(),
      contract.getthirdToTenthLevelBonus(), // 更正函数名
      contract.getWithdrawableDividends(),
    ]);

    // 更新各项奖金数据
    this.totalRewards = this.formatUSDT(totalDividends);
    this.dailyDividend = this.formatUSDT(dailyDividend);
    this.firstLevelBonus = this.formatUSDT(firstLevelBonus);
    this.secondLevelBonus = this.formatUSDT(secondLevelBonus);
    this.thirdToTenthLevelBonus = this.formatUSDT(thirdToTenthLevelBonus);
    this.withdrawableDividends = this.formatUSDT(withdrawableDividends);

  } catch (error) {
    console.error("查询奖金失败", error);
    alert("查询失败，请确保已连接到MetaMask并检查网络。");
  }
},
    // 获取用户每次结算的分红明细
    async getUserDividendsPerRound() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const address = await signer.getAddress();
        const dividends = await contract.getMemberTotalDividendsPerRound(address);
        this.dividendsPerRound = dividends;
      } catch (error) {
        console.error("获取分红明细失败", error);
        alert("获取分红明细失败，请检查控制台。");
      }
    },
    // 提现奖金
    async withdrawDividends() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const amountInWei = ethers.utils.parseUnits(this.amountToWithdraw.toString(), 6);
        const withdrawTx = await contract.withdrawDividends(amountInWei);
        await withdrawTx.wait();

        alert("提取成功！");
        this.getUserBonuses();  // 刷新奖金数据
      } catch (error) {
        console.error("提取失败", error);
        alert("提取失败，错误详情见控制台。");
      }
    },
    // 格式化USDT数值
    formatUSDT(value) {
      return ethers.utils.formatUnits(value, 6);
    },
    // 格式化时间戳
    formatTimestamp(index) {
      // 假设每次结算间隔为1天，从当前时间往前推算
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date.toLocaleDateString();
    }
  },
  // 组件挂载时获取用户奖金信息和每次结算分红明细
  mounted() {
    this.getUserBonuses();
    this.getUserDividendsPerRound();
  }
}
</script>

<style scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/detill.css";

/* 添加滚动条样式并固定标题 */
.dividends-scrollable {
  max-height: 160px; /* 设定最大高度以启用滚动 */
  overflow-y: auto; /* 当内容超出时自动出现垂直滚动条 */
  position: relative; /* 为子元素定位提供上下文 */
}

/* 固定分红明细标题 */
.main-content h3 {
  position: sticky; /* 固定在顶部 */
  top: 0; /* 距离顶部的距离 */
  background-color: red; /* 保证标题滚动时背景清晰可见 */
  z-index: 1; /* 确保标题在滚动时位于其他元素之上 */
}

.main {
  padding-top: 30px;
}
</style>