<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <p style="color: red;">SBS</p>
      </div>
      <div class="main">
        <div class="itemheader">
          <img src="@/assets/images/ban-2.png" alt="">
          <div>
            <img src="@/assets/images/kj2.jpg" alt="" style="width:100%; height: 100%;">
          </div>
        </div>

        <h3 class="title" style="color: red;">矿机明细</h3>

        <div class="card" style="background-color: rgba(245,245,245,0.8);display:flex;flex-direction:column;justify-content:space-around;">
          <div class="bet">
            <p style="color: red;">我的矿机总数量：</p>
            <p style="color: red;">{{ myTotalMachines }}台</p>
          </div>
          <div class="bet">
            <p style="color: red;">我的1号矿机：</p>
            <p style="color: red;">{{ myOneMachineCount }}台</p>
          </div>
          <div class="bet">
            <p style="color: red;">我的2号矿机：</p>
            <p style="color: red;">{{ myTwoMachineCount }}台</p>
          </div>
          <div class="bet">
            <p style="color: red;">我的3号矿机：</p>
            <p style="color: red;">{{ myThreeMachineCount }}台</p>
          </div>
          <!-- <div class="bet">
            <p style="color: red;">运行中的矿机</p>
            <p style="color: red;">{{ activeMachines }}台</p>
          </div> -->
          <!-- <div class="bet">
            <p style="color: red;">过期的矿机</p>
            <p style="color: red;">{{ expiredMachines }}台</p>
          </div> -->
          <div class="bet">
            <p style="color: red;">赠送的1号矿机数量：</p>
            <p style="color: red;">{{ rewardedOneMachineCount }}台</p>
          </div>
          <div class="bet">
            <p style="color: red;">我的团队矿机总数量：</p>
            <p style="color: red;">{{ teamMachines }}台</p>
          </div>
       
          <div class="bet">
            <p style="color: red;">我的团队2号矿机数量：</p>
            <p style="color: red;">{{ teamTwoMachineCount }}台</p>
          </div>
          <div class="bet">
            <p style="color: red;">我的团队3号矿机数量：</p>
            <p style="color: red;">{{ teamThreeMachineCount }}台</p>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { usdtTokenAddress, usdtAbi, contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'item',
  data() {
    return {
      roate: false,
      myTotalMachines: 0,
      myOneMachineCount: 0,
      myTwoMachineCount: 0,
      myThreeMachineCount: 0,
      activeMachines: 0,
      expiredMachines: 0,
      teamMachines: 0,
      rewardedOneMachineCount: 0,
      teamTwoMachineCount: 0,
      teamThreeMachineCount: 0,
      walletAddress: '未连接',
      shortWalletAddress: '未连接',
      walletBalanceTop: '0'
    }
  },
  mounted() {
    this.connectWallet();
  },
  methods: {
    async connectWallet() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const address = await signer.getAddress();

          const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, provider);
          const balance = await usdtContract.balanceOf(address);
          const balanceInUsdt = ethers.utils.formatUnits(balance, 18);
          const balanceInUsdtInt = Math.floor(balanceInUsdt);

          this.walletAddress = address;
          this.shortWalletAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
          this.walletBalanceTop = balanceInUsdtInt;

          this.fetchMachineCounts(signer);
        } catch (error) {
          console.error(error);
          alert('连接钱包失败');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async fetchMachineCounts(signer) {
      try {
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        // 获取所有需要的数据
        const [
          myTotalMachines, 
          myOneMachineCount, 
          myTwoMachineCount, 
          myThreeMachineCount
        ] = await contract.getMemberMachines(this.walletAddress);

        // 获取团队矿机数量和赠送1号矿机数量
        const teamMachines = await contract.getTeamMachineCount(this.walletAddress);
        const rewardedOneMachineCount = await contract.getRewardedOneMachineCount();
        const [teamTwoMachineCount, teamThreeMachineCount] = await contract.getTeamMachineCounts(this.walletAddress);

        // 转换并设置数据
        this.myTotalMachines = parseInt(myTotalMachines);
        this.myOneMachineCount = parseInt(myOneMachineCount);
        this.myTwoMachineCount = parseInt(myTwoMachineCount);
        this.myThreeMachineCount = parseInt(myThreeMachineCount);
        this.teamMachines = parseInt(teamMachines);
        this.rewardedOneMachineCount = parseInt(rewardedOneMachineCount);
        this.teamTwoMachineCount = parseInt(teamTwoMachineCount);
        this.teamThreeMachineCount = parseInt(teamThreeMachineCount);

        // 计算运行中的矿机数量
        this.activeMachines = this.myTotalMachines - this.expiredMachines;
      } catch (error) {
        console.error('Error fetching machine counts:', error);
        alert('获取矿机数据失败，请重试');
      }
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/item.css";

.card {
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  background-color: #f0f0f0;
  overflow: auto;
  max-height: 300px;
}

.bet {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.svgroate {
  animation: rotate 2s linear infinite;
  width: 80px;
  height: 80px;
}

.arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

p {
  color: red;
} 

 h3 {
  color: red;
}
</style>