<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <p>设置</p>
      </div>
      <div class="main">
        <button class="return" @click="goback"><img src="@/assets/images/left-arrow.png" alt=""></button>
        <div class="main-content">
          <!-- 只有合约拥有者或多重签名钱包地址可以看到和使用的功能 -->
          <template v-if="isOwner || isMultiSigWallet">
            <!-- 显示钱包内SBS余额 -->
            <p>钱包内SBS余额：{{ walletSBSTokenBalance }} SBS</p>
            <!-- 显示购买功能状态 -->
            <p>购买功能状态：{{ isBuyEnabled ? '已开启' : '已关闭' }}</p>

            <!-- 添加黑名单 -->
            <div>
              <input v-model="blacklistAddress" placeholder="输入要添加到黑名单的地址" />
              <button @click="addBlacklist">添加黑名单</button>
            </div>
            <!-- 移除黑名单 -->
            <div>
              <input v-model="removeBlacklistAddress" placeholder="输入要从黑名单中移除的地址" />
              <button @click="removeBlacklist">移除黑名单</button>
            </div>
            <!-- 启用或禁用代币购买功能 -->
            <div>
              <label>
                <input v-model="tempIsBuyEnabled" type="checkbox" />
                启用代币购买功能
              </label>
              <button @click="toggleBuyFunction">设置购买功能</button>
            </div>
            <!-- 设置新的DEX池地址 -->
            <div>
              <input v-model="newDexPoolAddress" placeholder="输入新的DEX池地址" />
              <button @click="setDexPoolAddress">设置DEX池地址</button>
            </div>
            <!-- 恢复所有权给多重签名钱包 -->
            <button @click="recoverOwnership">恢复所有权给多重签名钱包</button>
            <!-- 设置新的多重签名钱包地址 -->
            <div>
              <input v-model="newMultiSigWalletAddress" placeholder="输入新的多重签名钱包地址" />
              <button @click="changeMultiSigWallet">设置多重签名钱包地址</button>
            </div>
            <!-- 放弃管理权限 -->
            <button @click="renounceOwnership">放弃管理权限</button>
            
            <!-- 显示DEX池地址 -->
            <p v-if="dexPoolAddress">DEX池地址：{{ formatAddress(dexPoolAddress) }}</p>
            <!-- 显示管理员钱包地址 -->
            <p v-if="adminWalletAddress">管理员钱包地址：{{ formatAddress(adminWalletAddress) }}</p>
            <!-- 显示多签钱包地址 -->
            <p v-if="multiSigWalletAddress">多签钱包地址：{{ formatAddress(multiSigWalletAddress) }}</p>
          </template>
          <!-- 非合约拥有者或多重签名钱包地址看到的信息 -->
          <div v-else>您不是合约拥有者或多重签名钱包地址，无法查看或执行这些操作。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';

// 从文件中导入合约地址和ABI
import { contractAddress, contractABI, sbsTokenAddress, sbsTokenABI } from '@/js/contractwo.js';

export default {
  name: 'settings',
  data() {
    return {
      isOwner: false,
      isMultiSigWallet: false,
      walletSBSTokenBalance: '0',
      provider: null,
      signer: null,
      contract: null,
      sbsTokenContract: null,
      blacklistAddress: '', // 用于输入要添加到黑名单的地址
      removeBlacklistAddress: '', // 用于输入要从黑名单中移除的地址
      isBuyEnabled: false, // 用于控制代币购买功能的状态
      tempIsBuyEnabled: false, // 临时存储用户勾选状态
      newDexPoolAddress: '', // 用于输入新的DEX池地址
      dexPoolAddress: '', // 用于显示当前的DEX池地址
      newMultiSigWalletAddress: '', // 用于输入新的多重签名钱包地址
      multiSigWalletAddress: '', // 用于显示当前的管理员钱包地址
      adminWalletAddress: '' // 用于显示当前合约管理员地址
    };
  },
  mounted() {
    this.initializeContract();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    // 初始化合约连接
    async initializeContract() {
      await this.connectWallet(); // 连接以太坊钱包
      await this.checkOwnershipAndMultiSig(); // 检查当前用户是否为合约拥有者或多重签名钱包地址
      if (this.isOwner || this.isMultiSigWallet) {
        await this.getWalletSBSTokenBalance(); // 获取钱包内SBS余额
        await this.getBuyEnabledStatus(); // 获取当前购买功能的状态
        await this.getDexPoolAddress(); // 获取当前DEX池地址
        await this.getMultiSigWalletAddress(); // 获取当前管理员钱包地址
        await this.getAdminWalletAddress(); // 获取当前合约管理员地址
      }
    },
    // 连接钱包
    async connectWallet() {
      try {
        if (typeof window.ethereum !== 'undefined') {
          this.provider = new ethers.providers.Web3Provider(window.ethereum); // 使用MetaMask连接
          await this.provider.send("eth_requestAccounts", []); // 请求用户授权
          this.signer = this.provider.getSigner(); // 获取用户签名者
          this.contract = new ethers.Contract(contractAddress, contractABI, this.signer); // 初始化合约对象
          this.sbsTokenContract = new ethers.Contract(sbsTokenAddress, sbsTokenABI, this.signer); // 初始化SBS代币合约对象
        } else {
          alert('未检测到以太坊钱包，请安装MetaMask。'); // 提示用户安装MetaMask
        }
      } catch (error) {
        console.error('连接钱包失败:', error);
        alert('连接钱包失败！');
      }
    },
    // 检查当前用户是否为合约拥有者或多重签名钱包地址
    async checkOwnershipAndMultiSig() {
      try {
        const currentAccount = await this.signer.getAddress(); // 获取用户钱包地址
        const ownerAddress = await this.contract.owner(); // 获取合约拥有者地址
        const multiSigWallet = await this.contract.multiSigWallet(); // 获取多签钱包地址

        // 比较用户钱包地址与合约拥有者地址，并忽略大小写
        this.isOwner = currentAccount.toLowerCase() === ownerAddress.toLowerCase();
        this.adminWalletAddress = ownerAddress;

        // 比较用户钱包地址与多签钱包地址，并忽略大小写
        this.isMultiSigWallet = currentAccount.toLowerCase() === multiSigWallet.toLowerCase();
      } catch (error) {
        console.error('检查合约拥有者或多重签名钱包地址失败:', error);
      }
    },
    // 获取当前DEX池地址
    async getDexPoolAddress() {
      try {
        this.dexPoolAddress = await this.contract.dexPoolAddress(); // 获取DEX池地址
      } catch (error) {
        console.error('获取DEX池地址失败:', error);
      }
    },
    // 获取当前管理员钱包地址
    async getMultiSigWalletAddress() {
      try {
        this.multiSigWalletAddress = await this.contract.multiSigWallet(); // 获取管理员钱包地址
      } catch (error) {
        console.error('获取管理员钱包地址失败:', error);
      }
    },
    // 获取当前合约管理员地址
    async getAdminWalletAddress() {
      try {
        this.adminWalletAddress = await this.contract.owner(); // 获取合约管理员地址
      } catch (error) {
        console.error('获取合约管理员地址失败:', error);
      }
    },
    // 将用户添加到黑名单中
    async addBlacklist() {
      if (!this.blacklistAddress || !ethers.utils.isAddress(this.blacklistAddress)) {
        alert('请输入有效的地址。');
        return;
      }
      try {
        const tx = await this.contract.addBlacklist(this.blacklistAddress); // 调用合约方法添加到黑名单
        await tx.wait(); // 等待交易被确认
        alert('将用户添加到黑名单成功！');
      } catch (error) {
        console.error('将用户添加到黑名单失败:', error);
        alert('将用户添加到黑名单失败：' + error.message);
      }
    },
    // 将用户从黑名单中移除
    async removeBlacklist() {
      if (!this.removeBlacklistAddress || !ethers.utils.isAddress(this.removeBlacklistAddress)) {
        alert('请输入有效的地址。');
        return;
      }
      try {
        const tx = await this.contract.removeBlacklist(this.removeBlacklistAddress); // 调用合约方法从黑名单移除
        await tx.wait(); // 等待交易被确认
        alert('将用户从黑名单中移除成功！');
      } catch (error) {
        console.error('将用户从黑名单中移除失败:', error);
        alert('将用户从黑名单中移除失败：' + error.message);
      }
    },
    // 启用或禁用代币购买功能
    async toggleBuyFunction() {
      try {
        const tx = await this.contract.toggleBuyFunction(this.tempIsBuyEnabled); // 调用合约方法启用/禁用购买功能
        await tx.wait(); // 等待交易被确认
        alert('设置购买功能成功！');
        // 更新购买功能状态
        await this.getBuyEnabledStatus();
      } catch (error) {
        console.error('设置购买功能失败:', error);
        alert('设置购买功能失败：' + error.message);
      }
    },
    // 设置新的DEX池地址
    async setDexPoolAddress() {
      if (!this.newDexPoolAddress || !ethers.utils.isAddress(this.newDexPoolAddress)) {
        alert('请输入有效的地址。');
        return;
      }
      try {
        const tx = await this.contract.setDexPoolAddress(this.newDexPoolAddress); // 调用合约方法设置新的DEX池地址
        await tx.wait(); // 等待交易被确认
        alert('设置新的DEX池地址成功！');
        // 更新DEX池地址
        await this.getDexPoolAddress();
      } catch (error) {
        console.error('设置新的DEX池地址失败:', error);
        alert('设置新的DEX池地址失败：' + error.message);
      }
    },
    // 恢复所有权给多重签名钱包
    async recoverOwnership() {
      try {
        const tx = await this.contract.recoverOwnership(); // 调用合约方法恢复所有权给多重签名钱包
        await tx.wait(); // 等待交易被确认
        alert('所有权恢复给多重签名钱包成功！');
        await this.checkOwnershipAndMultiSig(); // 更新所有权状态
        // 更新管理员钱包地址
        await this.getMultiSigWalletAddress();
      } catch (error) {
        console.error('恢复所有权失败:', error);
        alert('恢复所有权失败：' + error.message);
      }
    },
    // 设置新的多重签名钱包地址
    async changeMultiSigWallet() {
      if (!this.newMultiSigWalletAddress || !ethers.utils.isAddress(this.newMultiSigWalletAddress)) {
        alert('请输入有效的地址。');
        return;
      }
      try {
        const tx = await this.contract.changeMultiSigWallet(this.newMultiSigWalletAddress); // 调用合约方法设置新的多重签名钱包地址
        await tx.wait(); // 等待交易被确认
        alert('设置新的多重签名钱包地址成功！');
        // 更新管理员钱包地址
        await this.getMultiSigWalletAddress();
      } catch (error) {
        console.error('设置新的多重签名钱包地址失败:', error);
        alert('设置新的多重签名钱包地址失败：' + error.message);
      }
    },
    // 获取当前购买功能的状态
    async getBuyEnabledStatus() {
      try {
        this.isBuyEnabled = await this.contract.buyEnabled(); // 获取购买功能状态
        this.tempIsBuyEnabled = this.isBuyEnabled; // 同步临时状态以匹配合约状态
      } catch (error) {
        console.error('获取购买功能状态失败:', error);
      }
    },
    // 合约拥有者放弃管理权限
    async renounceOwnership() {
      try {
        const tx = await this.contract.renounceOwnership(); // 调用合约方法放弃所有权
        await tx.wait(); // 等待交易被确认
        alert('放弃管理权限成功！');
        await this.checkOwnershipAndMultiSig(); // 更新所有权状态
      } catch (error) {
        console.error('放弃管理权限失败:', error);
        alert('放弃管理权限失败：' + error.message);
      }
    },
    // 获取钱包内的SBS余额
    async getWalletSBSTokenBalance() {
      try {
        const currentAccount = await this.signer.getAddress(); // 获取用户钱包地址
        const balance = await this.sbsTokenContract.balanceOf(currentAccount); // 获取钱包内SBS余额
        this.walletSBSTokenBalance = parseFloat(ethers.utils.formatUnits(balance, 6)).toFixed(2); // 格式化后更新
      } catch (error) {
        console.error('查询钱包内SBS余额失败:', error);
        alert('查询钱包内SBS余额失败！');
      }
    },
    
    // 截短地址
    formatAddress(address) {
      if (address && address.length > 8) {
        return `${address.slice(0, 4)}...${address.slice(-4)}`;
      }
      return address;
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/settings.css";
@import "@/assets/css/style.css";

.main {
  padding-top: 50px;
}
</style>