<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <p>设置</p>
      </div>
      <div class="main">
        <button class="return" @click="goback"><img src="@/assets/images/left-arrow.png" alt=""></button>
        <div class="main-content">
          <!-- 新增按钮 -->
          <button @click="navigateToSBSTokenManagement">SBS代币管理</button>

          <!-- 只有合约拥有者可以看到和使用的功能 -->
          <template v-if="isOwner">
            <!-- 分批次计算分红 -->
            <label for="batchSize">批次大小：</label>
            <input type="number" id="batchSize" v-model="batchSize" placeholder="请输入批次大小" />
            <button @click="calculateDividends">计算分红</button>
            <button @click="resetBatchIndex">重置批次索引</button>

            <p>未结算会员人数：{{ unsettledMembersCount }}</p>
            <p>拨出的总SBS：{{ totalDividendsDistributed }} SBS</p>

            <!-- 显示钱包内SBS余额 -->
            <p>钱包内SBS余额：{{ walletSBSTokenBalance }} SBS</p>

            <!-- 添加 SBS 功能 -->
            <label for="sbsDepositAmount">SBS充值金额：</label>
            <input type="number" id="sbsDepositAmount" v-model="sbsDepositAmount" placeholder="请输入充值金额 (SBS)" />
            <button @click="approveContractToTransferSBS">批准充值SBS</button>
            <button @click="depositSBS">充值SBS</button>

            <p>合约SBS余额：{{ contractSBSTokenBalance }} SBS</p>

            <label for="sbsRecipientAddress">SBS接收地址：</label>
            <input type="text" id="sbsRecipientAddress" v-model="sbsRecipientAddress" placeholder="请输入SBS接收地址" />
            <label for="sbsTransferAmount">SBS转账金额：</label>
            <input type="number" id="sbsTransferAmount" v-model="sbsTransferAmount" placeholder="请输入SBS转账金额" />
            <button @click="transferSBSToken">转账SBS</button>

            <!-- 设置矿机分红比例 -->
            <label for="oneMachineDividendRate">1号矿机分红比例（_rate）：</label>
            <input type="number" id="oneMachineDividendRate" v-model="oneMachineDividendRate" placeholder="请输入比例（单位：%）" />
            <button @click="setOneMachineDividendRate">设置1号矿机分红比例</button>

            <label for="twoMachineDividendRate">2号矿机分红比例（_rate）：</label>
            <input type="number" id="twoMachineDividendRate" v-model="twoMachineDividendRate" placeholder="请输入比例（单位：%）" />
            <button @click="setTwoMachineDividendRate">设置2号矿机分红比例</button>

            <label for="threeMachineDividendRate">3号矿机分红比例（_rate）：</label>
            <input type="number" id="threeMachineDividendRate" v-model="threeMachineDividendRate" placeholder="请输入比例（单位：%）" />
            <button @click="setThreeMachineDividendRate">设置3号矿机分红比例</button>

            <!-- 设置推荐奖金代数范围开始 -->
            <label for="referralBonusStartLevel">推荐奖金代数范围开始：</label>
            <input type="number" id="referralBonusStartLevel" v-model="referralBonusStartLevel" placeholder="请输入开始代数" />
            <button @click="setReferralBonusStart">设置推荐奖金开始代数</button>

              <!-- 新增3-10代分红比例设置 -->
        <!-- <label for="thirdToTenthLevelDividendRate">3-10代分红比例（_rate）：</label>
        <input type="number" id="thirdToTenthLevelDividendRate" v-model="thirdToTenthLevelDividendRate" placeholder="请输入比例（单位：%）" />
        <button @click="setThirdToTenthMachineDividendRate">设置3-10代分红比例</button> -->

         <!-- 设置推荐奖金代数范围结束 -->
           <label for="referralBonusEndLevel">推荐奖金代数范围结束：</label>
            <input type="number" id="referralBonusEndLevel" v-model="referralBonusEndLevel" placeholder="请输入结束代数" />
            <button @click="setReferralBonusEnd">设置推荐奖金结束代数</button>
             
          </template>
        
       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI, sbsTokenAddress, sbsTokenABI } from '@/js/contract.js';

export default {
  name: 'settings',
  data() {
    return {
      recipientAddress: '',
      isOwner: false,
      totalDividendsDistributed: '0',
      sbsRecipientAddress: '',
      sbsTransferAmount: '',
      sbsDepositAmount: '',
      contractSBSTokenBalance: '0',
      walletSBSTokenBalance: '0',
      provider: null,
      signer: null,
      contract: null,
      sbsTokenContract: null,
      oneMachineDividendRate: '',
      twoMachineDividendRate: '',
      threeMachineDividendRate: '',
      referralBonusStartLevel: '',
      referralBonusEndLevel: '',
      batchSize: '', // 批量大小
      unsettledMembersCount: 0 ,// 待结算会员数量
      thirdToTenthLevelDividendRate: '' // 新增3-10代分红比例属性
    };
  },
  mounted() {
    this.initializeContract();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    // 新增导航函数
    navigateToSBSTokenManagement() {
      this.$router.push({ name: 'SBSToken' });
    },
    // 初始化合约连接
    async initializeContract() {
      await this.connectWallet();
      await this.checkOwnership();
      if (this.isOwner) {
        await this.getTotalDividendsDistributed();
        await this.getContractSBSTokenBalance();
        await this.getWalletSBSTokenBalance();
        await this.getUnsettledMembersCount(); // 获取待结算会员数量
      }
    },
    // 连接钱包
    async connectWallet() {
      try {
        if (typeof window.ethereum !== 'undefined') {
          this.provider = new ethers.providers.Web3Provider(window.ethereum);
          await this.provider.send("eth_requestAccounts", []);
          this.signer = this.provider.getSigner();
          this.contract = new ethers.Contract(contractAddress, contractABI, this.signer);
          this.sbsTokenContract = new ethers.Contract(sbsTokenAddress, sbsTokenABI, this.signer);
        } else {
          alert('未检测到以太坊钱包，请安装MetaMask。');
        }
      } catch (error) {
        console.error('连接钱包失败:', error);
        alert('连接钱包失败！');
      }
    },
     // 设置3-10代分红比例方法
     async setThirdToTenthMachineDividendRate() {
      if (!this.thirdToTenthLevelDividendRate || parseFloat(this.thirdToTenthLevelDividendRate) <= 0) {
        alert('请输入有效的3-10代分红比例。');
        return;
      }
      try {
        const ratePPM = this.toPPM(this.thirdToTenthLevelDividendRate);
        const tx = await this.contract.setThirdToTenthLevelDividendRate(ratePPM);
        await tx.wait();
        alert('3-10代分红比例设置成功！');
      } catch (error) {
        console.error('设置3-10代分红比例失败:', error);
        alert('设置3-10代分红比例失败：' + error.message);
      }
    },
 
    // 检查当前用户是否为合约拥有者
    async checkOwnership() {
      try {
        const currentAccount = await this.signer.getAddress();
        const ownerAddress = await this.contract.owner();
        this.isOwner = currentAccount.toLowerCase() === ownerAddress.toLowerCase();
      } catch (error) {
        console.error('检查合约拥有者失败:', error);
        alert('检查合约拥有者失败：' + error.message);
      }
    },
    // 获取总分红金额
  
     async getTotalDividendsDistributed() {
      try {
        const totalDividends = await this.contract.viewTotalDividendsDistributed();
        this.totalDividendsDistributed = ethers.utils.formatUnits(totalDividends, 6);
      } catch (error) {
        console.error('查询总拨出奖金失败:', error);
        alert('查询总拨出奖金失败：' + error.message);
      }
    },
    // 其他方法...
    // 计算分红
    async calculateDividends() {
      if (!this.batchSize || parseFloat(this.batchSize) <= 0) {
        alert('请输入有效的批次大小。');
        return;
      }
      try {
        const batchSize = parseInt(this.batchSize);
        const tx = await this.contract.calculateDividends(batchSize); // 传递批次大小作为参数
        await tx.wait();
        alert('分红计算成功！');
        await this.getTotalDividendsDistributed();
        await this.getUnsettledMembersCount(); // 更新待结算会员数量
      } catch (error) {
        console.error('分红计算失败:', error);
        alert('分红计算失败：' + error.message);
      }
    },
    // 重置批次索引
    async resetBatchIndex() {
      try {
        const tx = await this.contract.resetBatchIndex();
        await tx.wait();
        alert('批次索引已重置！');
        this.batchSize = ''; // 清空批次大小输入框
        await this.getUnsettledMembersCount(); // 更新待结算会员数量
      } catch (error) {
        console.error('重置批次索引失败:', error);
        alert('重置批次索引失败：' + error.message);
      }
    },
    // 获取待结算会员数量
    async getUnsettledMembersCount() {
      try {
        const count = await this.contract.getUnsettledMembersCount();
        this.unsettledMembersCount = count.toString();
      } catch (error) {
        console.error('查询待结算会员数量失败:', error);
        alert('查询待结算会员数量失败：' + error.message);
      }
    },
    
    // 批准合约从钱包转移SBS代币
    async approveContractToTransferSBS() {
      if (!this.sbsDepositAmount || parseFloat(this.sbsDepositAmount) <= 0) {
        alert('请输入有效的SBS充值金额。');
        return;
      }
      try {
        const amount = ethers.utils.parseUnits(this.sbsDepositAmount, 6); // 假设SBS的decimals是6
        const tx = await this.sbsTokenContract.approve(contractAddress, amount);
        await tx.wait();
        alert('合约已批准转移SBS代币！');
      } catch (error) {
        console.error('批准转移SBS代币失败:', error);
        alert('批准转移SBS代币失败：' + error.message);
      }
    },
    
    // 充值SBS代币到合约
    async depositSBS() {
      if (!this.sbsDepositAmount || parseFloat(this.sbsDepositAmount) <= 0) {
        alert('请输入有效的SBS充值金额。');
        return;
      }
      try {
        const amount = ethers.utils.parseUnits(this.sbsDepositAmount, 6); // 假设SBS的decimals是6
        const allowance = await this.sbsTokenContract.allowance(await this.signer.getAddress(), contractAddress);
        if (amount.gt(allowance)) {
          alert('请先批准合约转移SBS代币。');
          return;
        }
        const tx = await this.contract.depositSBS(amount);
        await tx.wait();
        alert('SBS充值成功！');
        await this.getContractSBSTokenBalance(); // 更新合约SBS余额
        await this.getWalletSBSTokenBalance(); // 更新钱包内SBS余额
      } catch (error) {
        console.error('SBS充值失败:', error);
        alert('SBS充值失败：' + error.message);
      }
    },
    
    // 获取合约中SBS的余额
    async getContractSBSTokenBalance() {
      try {
        const balance = await this.sbsTokenContract.balanceOf(contractAddress);
        this.contractSBSTokenBalance = parseFloat(ethers.utils.formatUnits(balance, 6)).toFixed(2); // 保留两位小数
      } catch (error) {
        console.error('查询合约SBS余额失败:', error);
        alert('查询合约SBS余额失败：' + error.message);
      }
    },
    
    // 向指定账户转账SBS代币
    async transferSBSToken() {
  if (!this.sbsRecipientAddress || !ethers.utils.isAddress(this.sbsRecipientAddress)) {
    alert('请输入有效的SBS接收地址。');
    return;
  }
  if (!this.sbsTransferAmount || parseFloat(this.sbsTransferAmount) <= 0) {
    alert('请输入有效的SBS转账金额。');
    return;
  }
  try {
    const amount = ethers.utils.parseUnits(this.sbsTransferAmount, 6); // 假设SBS的decimals是6
    const balance = await this.sbsTokenContract.balanceOf(contractAddress);
    if (amount.gt(balance)) {
      alert('合约内SBS余额不足。');
      return;
    }
    const tx = await this.contract.transferSBSToken(this.sbsRecipientAddress, amount); // 调用合约的transferSBSToken方法
    await tx.wait();
    alert('SBS转账成功！');
    await this.getContractSBSTokenBalance(); // 更新合约SBS余额
    await this.getWalletSBSTokenBalance(); // 更新钱包内SBS余额
  } catch (error) {
    console.error('SBS转账失败:', error);
    alert('SBS转账失败：' + error.message);
  }
},
    
    // 获取钱包内的SBS余额
    async getWalletSBSTokenBalance() {
      try {
        const currentAccount = await this.signer.getAddress();
        const balance = await this.sbsTokenContract.balanceOf(currentAccount);
        this.walletSBSTokenBalance = parseFloat(ethers.utils.formatUnits(balance, 6)).toFixed(2); // 保留两位小数
      } catch (error) {
        console.error('查询钱包内SBS余额失败:', error);
        alert('查询钱包内SBS余额失败：' + error.message);
      }
    },
    
    // 新增函数：计算分红比例并转换为ppm
    toPPM(rate) {
      return (parseFloat(rate) * 1000000).toFixed(0).toString();
    },
    
    // 设置1号矿机分红比例
    async setOneMachineDividendRate() {
      if (!this.oneMachineDividendRate || parseFloat(this.oneMachineDividendRate) <= 0) {
        alert('请输入有效的1号矿机分红比例。');
        return;
      }
      try {
        const ratePPM = this.toPPM(this.oneMachineDividendRate);
        const tx = await this.contract.setOneMachineDividendRate(ratePPM);
        await tx.wait();
        alert('1号矿机分红比例设置成功！');
      } catch (error) {
        console.error('设置1号矿机分红比例失败:', error);
        alert('设置1号矿机分红比例失败：' + error.message);
      }
    },
    
    // 设置2号矿机分红比例
    async setTwoMachineDividendRate() {
      if (!this.twoMachineDividendRate || parseFloat(this.twoMachineDividendRate) <= 0) {
        alert('请输入有效的2号矿机分红比例。');
        return;
      }
      try {
        const ratePPM = this.toPPM(this.twoMachineDividendRate);
        const tx = await this.contract.setTwoMachineDividendRate(ratePPM);
        await tx.wait();
        alert('2号矿机分红比例设置成功！');
      } catch (error) {
        console.error('设置2号矿机分红比例失败:', error);
        alert('设置2号矿机分红比例失败：' + error.message);
      }
    },
    
    // 设置3号矿机分红比例
    async setThreeMachineDividendRate() {
      if (!this.threeMachineDividendRate || parseFloat(this.threeMachineDividendRate) <= 0) {
        alert('请输入有效的3号矿机分红比例。');
        return;
      }
      try {
        const ratePPM = this.toPPM(this.threeMachineDividendRate);
        const tx = await this.contract.setThreeMachineDividendRate(ratePPM);
        await tx.wait();
        alert('3号矿机分红比例设置成功！');
      } catch (error) {
        console.error('设置3号矿机分红比例失败:', error);
        alert('设置3号矿机分红比例失败：' + error.message);
      }
    },
    
    // 设置推荐奖金代数范围开始
    async setReferralBonusStart() {
      if (!this.referralBonusStartLevel || parseFloat(this.referralBonusStartLevel) < 0) {
        alert('请输入有效的推荐奖金开始代数。');
        return;
      }
      try {
        const startLevel = parseInt(this.referralBonusStartLevel);
        if (startLevel > this.referralBonusEndLevel) {
          alert('开始代数不能大于结束代数。');
          return;
        }
        const tx = await this.contract.setReferralBonusStartLevel(startLevel);
        await tx.wait();
        alert('推荐奖金开始代数设置成功！');
      } catch (error) {
        console.error('设置推荐奖金开始代数失败:', error);
        alert('设置推荐奖金开始代数失败：' + error.message);
      }
    },
    
    // 设置推荐奖金代数范围结束
    async setReferralBonusEnd() {
      if (!this.referralBonusEndLevel || parseFloat(this.referralBonusEndLevel) < 0) {
        alert('请输入有效的推荐奖金结束代数。');
        return;
      }
      try {
        const endLevel = parseInt(this.referralBonusEndLevel);
        if (endLevel < this.referralBonusStartLevel) {
          alert('结束代数不能小于开始代数。');
          return;
        }
        const tx = await this.contract.setReferralBonusEndLevel(endLevel);
        await tx.wait();
        alert('推荐奖金结束代数设置成功！');
      } catch (error) {
        console.error('设置推荐奖金结束代数失败:', error);
        alert('设置推荐奖金结束代数失败：' + error.message);
      }
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/settings.css";
@import "@/assets/css/style.css";

.main {
  padding-top: 50px;
}
</style>