<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <div class="topid">
          <div id="walletAddress"> {{ shortWalletAddress }}</div>
          <div id="walletBalanceTop">余额: {{ walletBalanceTop }} USDT</div>
        </div>
      </div>
      <div class="main">
        <div class="header">
          <p>Welcome</p>
        </div>
        <div class="affivhe">
          <img src="@/assets/images/gg.png" alt="">
          <div id="vipStatus" class="top-left-corner"  style="color: yellow;">{{ memberStatus }}</div>
        </div>
        <div class="banner">
          <div class="item1">
            <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="card1">
                    <img src="@/assets/images/ban-1.png" alt="">
                    <h2>1.66500000</h2>
                    <p>矿工盛宴</p>
                    <b><span>SBSCLUB</span></b>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="card1">
                    <img src="@/assets/images/ban-1.png" alt="">
                    <h2>0.82600000</h2>
                    <p>我的SBS</p>
                    <b><span>互助共赢</span></b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SBS1号矿机 -->
        <div class="community" style="display: flex;flex-direction: column;justify-content: center;">
          <h4 style="color:#3b7cf5;line-height:1.5">SBS&nbsp;1号矿机<h6 style="color: red;">算力:100 Th/s<h6>有效期:长期有效<h6>价格:100 USDT</h6>
              </h6>
            </h6>
          </h4>
          <input type="number" v-model="machineCount1" placeholder="输入数量">
          <button @click="purchaseMachines(0, machineCount1)">购买</button>
          <img src="@/assets/images/kj2.jpg" alt="" style="background-color: transparent;">
        </div>

        <!-- SBS2号矿机 -->
        <div class="community" style="display: flex;flex-direction: column;justify-content: center;">
          <h4 style="color:#3b7cf5;line-height:1.5">SBS&nbsp;2号矿机<h6 style="color: red;">算力:300 Th/s<h6>有效期:长期有效<h6>价格:200 USDT</h6>
              </h6>
            </h6>
          </h4>
          <input type="number" v-model="machineCount2" placeholder="输入数量">
          <button @click="purchaseMachines(1, machineCount2)">购买</button>
          <img src="@/assets/images/kj2.jpg" alt="" style="background-color: transparent;">
        </div>

        <!-- SBS3号矿机 -->
        <div class="community" style="display: flex;flex-direction: column;justify-content: center;">
          <h4 style="color:#3b7cf5;line-height:1.5">SBS&nbsp;3号矿机<h6 style="color: red;">算力:600 Th/s<h6>有效期:长期有效<h6>价格:300 USDT</h6>
              </h6>
            </h6>
          </h4>
          <input type="number" v-model="machineCount3" placeholder="输入数量">
          <button @click="purchaseMachines(2, machineCount3)">购买</button>
          <img src="@/assets/images/kj2.jpg" alt="" style="background-color: transparent;">
        </div>

        <div class="set">
          <input type="text" id="referrer-address" v-model="referrerAddress" placeholder="输入推荐人地址">
          <div class="input-group">
            <label for="machine-type">矿机类型：</label>
            <select id="machine-type" v-model="machineType">
              <option value="0" > SBS 1号矿机</option>
              <option value="1" > SBS 2号矿机</option>
              <option value="2" > SBS 3号矿机</option>
            </select>
          </div>
          <div class="input-group">
            <label for="machine-count">矿机数量：</label>
            <input type="number" id="machine-count" v-model="machineCount" min="1" placeholder="输入数量">
          </div>
          <button @click="registerMember">绑定推荐人并购买矿机</button>
        </div>

        <textarea id="clipboardContent" style="position: absolute; top: -9999px; left: -9999px;"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
/* global ethereum */
import Swiper from 'swiper';
import { ethers } from 'ethers';
import { usdtTokenAddress, usdtAbi, contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'home',
  data() {
    return {
      walletAddress: '未连接', 
      shortWalletAddress: '未连接', 
      walletBalanceTop: '0', 
      memberStatus: '未获取', 
      machineCount1: 1, 
      machineCount2: 1, 
      machineCount3: 1, 
      machineCount: 1, 
      machineType: 0,
      referrerAddress: '' // 添加推荐人地址
    };
  },
  mounted() {
    new Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    });

    this.connectWallet();
    this.getAndDisplayMemberStatus();
    this.populateReferrerFromURL(); // 调用解析URL参数的方法
  },
  methods: {
    async connectWallet() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const address = await signer.getAddress();

          const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, provider);
          const balance = await usdtContract.balanceOf(address);
          const balanceInUsdt = ethers.utils.formatUnits(balance, 6);
          const balanceInUsdtInt = Math.floor(balanceInUsdt);

          this.walletAddress = address;
          this.shortWalletAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
          this.walletBalanceTop = balanceInUsdtInt; 

        } catch (error) {
          console.error(error);
          alert('连接钱包失败');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async getAndDisplayMemberStatus() {
      if (!window.ethereum) {
        alert('请安装 MetaMask 插件');
        return;
      }

      try {
        await ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
        const status = await contract.getAccountStatus({ from: userAddress });

        this.memberStatus = status;
      } catch (error) {
        console.error('获取会员状态失败:', error);
        alert('获取会员状态失败，请查看控制台获取详细信息。');
      }
    },
    async registerMember() {
      const referrerAddress = this.referrerAddress;
      const machineType = parseInt(this.machineType);
      const machineCount = parseInt(this.machineCount);

      if (!referrerAddress || machineCount <= 0) {
        alert('请输入推荐人地址并选择矿机数量');
        return;
      }

      if (window.ethereum) {
        try {
          await ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const userAddress = await signer.getAddress();

          let machinePrice;
          if (machineType === 0) {
            machinePrice = 100;
          } else if (machineType === 1) {
            machinePrice = 200;
          } else if (machineType === 2) {
            machinePrice = 300;
          }
          const totalCost = ethers.utils.parseUnits((machineCount * machinePrice).toString(), 6);

          const usdtToken = new ethers.Contract(usdtTokenAddress, usdtAbi, signer);

          let allowance = await usdtToken.allowance(userAddress, contractAddress);
          if (!allowance.gte(totalCost)) {
            const approveTx = await usdtToken.approve(contractAddress, totalCost);
            await approveTx.wait();
          }

          const contract = new ethers.Contract(contractAddress, contractABI, signer);
          const tx = await contract.registerAndPurchase(referrerAddress, machineType, machineCount);
          await tx.wait();

          alert('注册成功并购买矿机');
        } catch (error) {
          console.error('注册过程中出错:', error);
          alert('注册失败。详情请查看控制台。');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async purchaseMachines(machineType, machineCount) {
      if (machineCount <= 0) {
        alert('请输入有效的矿机数量');
        return;
      }

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        let machinePrice = 0;
        if (machineType === 0) {
          machinePrice = 100;
        } else if (machineType === 1) {
          machinePrice = 200;
        } else if (machineType === 2) {
          machinePrice = 300;
        }
        const totalCost = ethers.utils.parseUnits((machineCount * machinePrice).toString(), 6);

        const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, signer);
        const allowance = await usdtContract.allowance(this.walletAddress, contractAddress);
        
        if (allowance.lt(totalCost)) {
          const approveTx = await usdtContract.approve(contractAddress, totalCost);
          await approveTx.wait();
        }

        const purchaseTx = await contract.purchaseMoreMachines(machineType, machineCount);
        await purchaseTx.wait();

        alert('购买成功');
      } catch (error) {
        console.error(error);
        alert('购买失败，请检查您的钱包余额或网络连接');
      }
    },
    populateReferrerFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      const referrer = urlParams.get('referrer');
      if (referrer) {
        this.referrerAddress = referrer;
      }
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/swiper-bundle.min.css";
@import "@/assets/css/index.css";
@import "@/assets/css/style.css";

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader svg {
  animation: rotate 2s linear infinite;
  width: 80px;
  height: 80px;
}

.arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
 
 .input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
} 

.input-group label {
  margin-right: 10px;
  width: 120px;
}

.input-group select,
.input-group input {
  flex: 1;
  padding: 5px;
  border: 1px solid #21977d;
  border-radius: 4px;
}

/* #walletAddress,
#walletBalanceTop {
  color: black;
} */

/* 更新后的样式 */
.input-group select,
.input-group input[type="number"] {
  flex: 0 0 195px;
  max-width: 195px;
  margin-left: -20px; /* 调整距离 */
}
</style>