import Vue from 'vue'
import Router from 'vue-router'
import homeview from "@/components/home.vue"
import itemview from "@/components/item.vue"
import userview from "@/components/user.vue"
import myteam from "@/components/myteam.vue"
import settingsview from "@/components/settings.vue"
import incomeview from "@/components/income.vue"
import copyinviteview from "@/components/copy-invite.vue"
import kj from "@/components/kj.vue"
import item1 from "@/components/item1.vue"
import SBSToken from '@/components/SBSToken.vue'
Vue.use(Router)
const routes=[
    {
        path:'/',
        redirect:'/home'
    },
    {
        path:'/home',
        component:homeview
    },
    {
        path:'/user',
        component:userview
    },
    {
        path:'/myteam',
        component:myteam
    },
    {
        path:'/settings',
        component:settingsview
    },
    {
        path:'/income',
        component:incomeview
    },
    {
        path:'/copy-invite',
        component:copyinviteview
    },
    {
        path:'/item',
        component:itemview
    },
    {
        path:'/kj',
        component:kj
    },
    {
        path:"/item1",
        component:item1
    },
    {
        path: '/SBSToken',
        name: 'SBSToken', // 添加名称配置
        component: SBSToken
      }



]
export default new Router({
    routes
})