<template>
  <div>
    <div class="maxbox">
      <div>
        <div class="top">
          <p>我的社区</p>
        </div>
        <div class="main">
          <button class="return" @click="goback">
            <img src="@/assets/images/left-arrow.png" alt="" />
          </button><br>

          <div class="number">
            <p class="inline-info">
              <span id="directReferrals">我的直接推荐人数: {{ directReferrals }}</span>
            </p>
            <p class="inline-info">
              <span id="totalReferrals">我的团队总人数: {{ totalReferrals }}</span>
            </p>
          </div><br>

          <div id="referrals" class="referrals-container">
            <div v-for="(member, index) in teamMembers" :key="index" class="pyuser">
              <img src="@/assets/images/pyusers.png" alt="" />
              <p class="address">{{ member }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { contractAddress, contractABI } from "@/js/contract.js";

export default {
  name: "myteam",
  data() {
    return {
      directReferrals: 0,
      totalReferrals: 0,
      teamMembers: [],
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async connectWallet() {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          return new ethers.providers.Web3Provider(window.ethereum);
        } catch (error) {
          console.error("用户拒绝了账户访问请求", error);
        }
      } else {
        console.error("未检测到Ethereum提供商。请安装MetaMask。");
      }
    },
    async fetchData() {
      const provider = await this.connectWallet();
      if (!provider) return;

      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const userAddress = await signer.getAddress();
        if (!userAddress) {
          alert("无法检测到用户地址，请先连接到MetaMask。");
          return;
        }

        // 获取直接推荐人数
        this.directReferrals = await contract.getDirectReferralCount();

        const [totalReferrals, teamMembers] = await contract.getTeamInfo();

        this.totalReferrals = totalReferrals;
        this.teamMembers = teamMembers;

      } catch (error) {
        console.error("查询数据失败", error);
        alert("查询失败，请确保已连接到MetaMask并检查网络。");
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
@import "@/assets/css/team.css";
@import "@/assets/css/style.css";

.inline-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main {
  padding-top: 50px;
}

.referrals-container {
  max-height: 400px;
  overflow-y: auto;
}

.pyuser {
  display: flex;
  align-items: center;
  gap: 10px;
}

.address {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>