<template>
    <div class="maxbox">
        <div>
            <div class="top">
                <p>SBC</p>
            </div>
            <div class="main" style="padding-top:50px">
                <div class="itemheader">
                    <img src="@/assets/images/ban-2.png" alt="">
                    <div>
                        <h2>SBC ECOL OGY</h2>
                        <p>开创区块链新时代</p>
                    </div>
                </div>

                <h3 class="title">MLRS生态板块图</h3>

                <div class="card" style="background-color: rgba(245,245,245,0.8);">
                    <p>&nbsp;&nbsp;&nbsp; 新加坡华人社区,

                        互助+私募项目，奖金秒结秒发，奖金100%拨出,没有泡沫,没有资金池，报单成功，奖金直接到钱包，不用提现。<br><br>
              

                        &nbsp;&nbsp;&nbsp;投资100USDT(约700元)成为VIP，三三倍增，从上到下,从左到右向下小公排,可拿下面1层见点奖70元,2层见点奖140元,
                        3层见点奖210元,4层见点奖280元,5层见点奖350元,6层见点奖420元,7层见点奖490元,8层见点奖560元,9层见点奖630元,<br><br>

                        &nbsp;&nbsp;&nbsp;目前,项目处于早期前段,待社区会员达到一定数量的时候,社区将适时发行完全去中心化,开源,透明的私募币,敬请关注.</p>
                </div>

                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'item',
}
</script>
<style scoped>

@import "@/assets/css/item.css";
.card {
          
          padding: 10px;
          margin: 10px 0;
          border-radius: 8px;
          background-color: #f0f0f0;
         
          overflow: auto;
          max-height: 200px;
         
      }
</style>