<template>
    <div>
        <div class="maxbox">
            <div>
                <div class="top">
                    <p>矿机</p>
                </div>
                <div class="main"
                    style="padding-top: 50px;display: flex;justify-content: center;align-items: center;flex-direction: column;height: 800px;">
                    <!-- 动态变化的计数器显示 -->
                    <h3>{{ displayNumber }}</h3>
                    <h3>{{ displayRate }}/秒</h3>
                    <img src="@/assets/images/kj2.jpg" alt="" style="width: 140px;height: 140px;">
                    <div class="content" style="left: 0;top:240px">
                        <img src="@/assets/images/f1-1.png" alt="" srcset="">
                        <div style="font-size:10px">矿机{{ totalMachines }}台</div>
                        <svg width="80" height="50" style="position: absolute;left:100%;top: 0;">
                            <polyline points="0,20 30,20 60,40" stroke="blue" stroke-width="1" fill="none" />
                            <circle cx="60" cy="40" r="5" stroke="blue" stroke-width="1" fill="white" />
                        </svg>
                    </div>
                    <div class="content" style="left: 0;top:350px">
                        <img src="@/assets/images/f2-1.png" alt="" srcset="">
                        <div style="font-size:10px">运行中{{ runningMachines }}台</div>
                        <svg width="80" height="50" style="position: absolute;left:100%;top: 0;">
                            <polyline points="0,20 20,20 " stroke="blue" stroke-width="1" fill="none" />
                            <circle cx="20" cy="20" r="5" stroke="blue" stroke-width="1" fill="white" />
                        </svg>
                    </div>
                    <div class="content" style="right: 0;top:240px">
                        <img src="@/assets/images/f3-1.png" alt="" srcset="">
                        <div style="font-size:10px">失效矿机{{ expiredMachines }}台</div>
                        <svg width="50" height="90" style="position: absolute;right:100%;top: 0;">
                            <polyline points="50,20 30,20 20,70" stroke="blue" stroke-width="1" fill="none" />
                            <circle cx="20" cy="70" r="5" stroke="blue" stroke-width="1" fill="white" />
                        </svg>
                    </div>
                    <br><br>
                    
                    <!-- 启动矿机按钮 -->
                    <div style="color:#3b7cf5;line-height:1.5;text-align:center;margin-top:20px">启动矿机</div>
                    <div class="loader">
                        <div style="display: flex;justify-content:center;align-items:center;position:relative">
                            <svg width="80" height="80" viewBox="0 0 100 100" :class="{ 'svgroate': isStarted }">
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
                                        <stop offset="0%" stop-color="white" />
                                        <stop offset="50%" stop-color="lightblue" />
                                        <stop offset="100%" stop-color="white" />
                                    </linearGradient>
                                </defs>
                                <circle cx="50" cy="50" r="45" stroke="url(#gradient)" stroke-width="10" fill="transparent" />
                            </svg>
                            <svg width="40" height="40" class="arrow" @click="startMachine">
                                <polyline points="0,20 40,20 20,0" stroke="white" stroke-width="4" fill="none" />
                                <polyline points="40,20 20,40" stroke="white" stroke-width="4" fill="none" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
    name: 'kuangji',
    data() {
        return {
            totalMachines: 0, // 总矿机数量
            runningMachines: 0, // 运行中的矿机数量
            displayNumber: 0, // 显示的数字
            displayRate: 0, // 显示的速率
            isStarted: false, // 矿机是否已启动
            counterInterval: null // 计数器的定时器
        };
    },
    mounted() {
        this.connectWallet();
        this.checkMachineStatus();
    },
    methods: {
        async connectWallet() {
            if (window.ethereum) {
                try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum); // 创建 Web3 提供者
                    await provider.send("eth_requestAccounts", []); // 请求用户连接钱包
                    const signer = provider.getSigner(); // 获取签名者
                    this.fetchMachineCounts(signer); // 获取矿机数量
                } catch (error) {
                    console.error(error);
                    alert('连接钱包失败');
                }
            } else {
                alert('请安装 MetaMask 插件');
            }
        },
        async fetchMachineCounts(signer) {
            try {
                const contract = new ethers.Contract(contractAddress, contractABI, signer); // 创建合约实例

                const totalMachines = await contract.getMachineCount(); // 获取总矿机数量
                this.totalMachines = parseInt(ethers.utils.formatUnits(totalMachines, 0)); // 格式化并设置总矿机数量
                this.runningMachines = this.totalMachines; // 假设所有矿机都在运行，因为去掉了失效矿机的逻辑
            } catch (error) {
                console.error('Error fetching machine counts:', error);
                alert('获取矿机数据失败，请重试');
            }
        },
        startCounter() {
            this.counterInterval = setInterval(() => {
                this.displayNumber = (parseFloat(this.displayNumber) + Math.random()).toFixed(5); // 更新显示的数字
                this.displayRate = (Math.random() * 0.00001).toFixed(10); // 更新显示的速率
            }, 1000); // 每秒更新一次
        },
        startMachine() {
            if (!this.isStarted) {
                this.isStarted = true; // 设置矿机启动状态
                localStorage.setItem('machineStarted', 'true'); // 保存矿机启动状态到本地存储
                this.startCounter(); // 开始计数器
            }
        },
        checkMachineStatus() {
            const machineStarted = localStorage.getItem('machineStarted'); // 从本地存储获取矿机启动状态
            if (machineStarted === 'true') {
                this.isStarted = true; // 设置矿机启动状态
                this.startCounter(); // 开始计数器
            }
        }
    }
}
</script>

<style scoped>
.button {
    background-color: #429dff;
    color: #fff;
    border: 0;
    width: 74px;
    height: 28px;
    border-radius: 4px;
    margin-top: 20px;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
}

.content>img {
    width: 40px;
    height: 40px;
}

.svgroate {
    animation: rotate 2s linear infinite;
}

.arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>